import React from "react";
// Customizable Area Start
import {  
  Box,
  Button,
  Typography,
  MenuItem,
  FormControl,
  Select,
  TextField,Checkbox,
  Dialog,
  IconButton, DialogTitle,
  DialogContent,
  Avatar,
  DialogActions,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
   } from "@mui/material";
import {

  styled,
  
} from "@mui/material/styles";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {  Close as CloseIcon } from "@mui/icons-material";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';

const TextContainer = styled(Box)({
  height:"auto",
  backgroundColor: '#f0f0f0',
  padding:"16px",
});
import { View } from "react-native";
// Customizable Area End

import HealthcareAccessFormController, { Props } from "./HealthcareAccessFormController.web";



export default class HealthcareAccessForm extends HealthcareAccessFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderFileListHarm = () => {
    return (
      <List>
        {this.state.selectedFiles.map((file, index) => (
          <ListItem 
          key={index} 
          divider 
          sx={webStyles.uploadButtonBox1}
          >
            <ListItemAvatar>
              <Avatar 
              style={webStyles.pendingImageBox}>
                <ImageOutlinedIcon 
                style={webStyles.defaultImageIcon} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText 
            primary={file.name} 
            secondary={`${(file.size / 1024).toFixed(2)} KB`}
            />
            <IconButton 
            edge="end"> 
            <MoreVertOutlinedIcon />
            </IconButton>
          </ListItem>
        )
      )
        }
      </List>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <NavigationMenu navigation={"HealthcareAccessForm"} id={"HealthcareAccessForm"}>
      <TextContainer>
          <Button data-test-id="btnGoback" sx={webStyles.goBackButton} onClick={()=>this.goback("LandingPage")}>
            <KeyboardArrowLeftIcon sx={{color:"black"}}/>
            <Typography style={webStyles.goBackText} >Go Back</Typography>
          </Button>
          <Typography style={webStyles.comunityType}>Referrals and Warm Hand Offs</Typography>
         
          <Button fullWidth sx={webStyles.mapButton}>Map</Button>
          <Box sx={{backgroundColor:"white",margin:0.5,borderRadius: "8px", }}>
          
          {this.state.allServices?.map((item, index) => (
                        <View data-test-id="View">
                       
                       {item.attributes.name !== "Other Services Offered" ?
                     <Box>
                       <Typography sx={[{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>{item.attributes.name}</Typography>
                       <FormControl fullWidth variant="outlined"
          sx={webStyles.formControl}>
                       <Select
            multiple
            value={this.state.selectedItems}
            // onChange={this.handleSelectChange}
            key={item.name}
            displayEmpty
            style={webStyles.selectMenu}
          data-test-id="SelectItem"
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
            renderValue={() => 'Select'
            }
          >   
         
            {item.attributes.sub_services?.map((option:any) => (
                  <span>
             
              <MenuItem key={option.name} value={option.name}
              style={{
                ...webStyles.checkBoxMenu,
                borderBottom:  '1px solid #CBD5E1',
               
              }}
            >
                <Box  sx={[webStyles.itemsMainBox,{ marginLeft:3, width: "100%"}]} key={option.name} >
                  <Box sx={[webStyles.checkboxContainer,]}>
                    <Checkbox
                    data-test-id="checkBoxIdMenu"
                      edge="start"
                      checked={option.checked}
                      tabIndex={-1}
                      disableRipple
                      onClick={() => this.handleToggle(item,option)}  
                      sx={{
                        color: "#64748B",width:"10%",
                        borderRadius: "6px",
                        '&.Mui-checked': {
                          color: "#CF9500",
                        },
                      }}
                    />
                    <Typography sx={{ textAlign: "left"}} style={webStyles.listItemName}>{option.name}</Typography>
                  </Box>
                 
                
                    
                </Box>
              
               
              </MenuItem>
         
              {this.state.openReserveBed && option.name==="Rehabilitation Center" && option.checked ? 
             <span style={{marginRight:2}}>   <MenuItem key={"option.name"} value={"option.name"}
                style={{
                  ...webStyles.checkBoxMenu,
                  borderBottom:  '1px solid #CBD5E1',
                 
                }}
              >
                  <Box  sx={[webStyles.itemsMainBox,{ marginLeft:3, width: "100%"}]} key={"option.name"} >
                    <Box sx={[webStyles.checkboxContainer,]}>
                     
                      <Typography sx={{ textAlign: "left"}} style={webStyles.listItemName}>Completed Detox Form?</Typography>
                      <Select
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                    }}
                      placeholder="Yes/No" renderValue={()=>this.state.options ? this.state.options: "Yes/No"} 
                      value={()=>this.state.options}>
                       <MenuItem 
                       data-test-id="checkBoxIdBed1"  style={{
                  ...webStyles.checkBoxMenu,
                  borderBottom:  '1px solid #CBD5E1',
                 
                }} onClick={()=>this.setState({options:"Yes"})}> Yes
                       </MenuItem>
                       <MenuItem data-test-id="checkBoxIdBed2" style={{
                  ...webStyles.checkBoxMenu,
                  borderBottom:  '1px solid #CBD5E1',
                 
                }} onClick={()=>this.setState({options:"No"})}> No
                       </MenuItem>
                      </Select>
                    
                    </Box>
                  </Box>
                </MenuItem>
                 <Button data-test-id="submitBtn" 
                 fullWidth   
                 sx={webStyles.referralButton}
                 >Referral
                 </Button>
                 </span>:
                 null}
                </span>
            ))}
          </Select>
                   </FormControl>

               </Box>  : null      }
                        </View>
                      ))} 
                        </Box>
          <Typography 
          style={webStyles.areaText}>
            Additional Items</Typography>
          <TextField fullWidth
            variant="outlined"
            size="small" multiline
            rows={3} placeholder="Add additional information" style={webStyles.subBoxinputField}
          />
        <Button 
        data-test-id="openModalTestId" 
        onClick={this.handleOpenHarm} 
        fullWidth 
        sx={webStyles.mapButton}>
          Add Picture
        </Button>
        <Button 
        data-test-id="submitBtn"  
        fullWidth sx={webStyles.submitButton}>
          Submit
          </Button>

        <Dialog data-test-id="dialogOpen"
            open={this.state.open} 
            onClose={this.handleCloseHarm}
            fullWidth 
            maxWidth="sm" 
            aria-labelledby="add-picture-dialog"
          >
            <DialogTitle>
              <span style={webStyles.addPictureHeadingText}>Add Picture</span>
              <IconButton
                data-test-id="closeModalTestId" 
                aria-label="close"
                onClick={this.handleCloseHarm} 
                sx={webStyles.addPictureIcon}
              >
                <CloseIcon /> </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              {this.renderFileListHarm()}
              <label>
                <Box
                  display="flex" 
                  alignItems="center"
                  sx={webStyles.uploadButtonBox1} 
                  style={{ cursor: 'pointer' }}
                >
                  <Avatar 
                  style={webStyles.photoCamera1}>
                    <CameraAltOutlinedIcon style={webStyles.cameraIcon} />
                  </Avatar>
                  <Typography 
                  variant="body1" 
                  style={webStyles.addPictureText}>
                    Add picture
                  </Typography>
                </Box>
               
                <input
                  type="file" 
                  accept="image/*"
                  multiple 
                  hidden 
                  data-test-id="fileChangeImageUpload"
                  onChange={this.handleFileChangeHarm}
                /> 
                </label>

            </DialogContent>
            {this.state.selectedFiles.length > 0 && (
              <DialogActions> 
                <Button
                  variant="contained"  
                  color="primary" fullWidth
                  data-test-id="uploadTestId"  
                  onClick={this.uploadImagesHarm}  sx={webStyles.uploadButton}
                >Upload
                </Button> </DialogActions>
            )}
          </Dialog>
     
        </TextContainer>
        </NavigationMenu>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyles = {
  modal:{
    position: 'absolute',
  top: '10%',
  margin:2,
  backgroundColor:"#F6F6F3",
  
  padding:2,
  
  },
  subBoxinputField: {
    marginBottom:"12px",
    fontSize: "16px",
    width:"100%",
    // color: "#94A3B8",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    backgroundColor: "white",
    fontWeight: 400,
    '& .MuiOutlinedInput-root': {
      borderRadius: "8px",
      '& fieldset': {
        borderColor: "transparent",
      },
      '&.Mui-focused fieldset': {
        borderColor: "transparent",
      },
      '&:hover fieldset': {
        borderColor: "transparent",
      },
    }
  },
  headerLogo: {
    width: "122.32px",
    height: "28px",
    backgroundColor: "#F5C502",
    borderRadius: 13,
    marginRight: 10
  },
  goBackButton: {
    display: 'inline-flex',
    alignItems: 'center',
    textTransform: 'none',
    gap:"2px",
  },
  goBackText: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#1D4ED8',
    lineHeight: '22px',
  },
  comunityType: {
    color:"#2D2A24",
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing:"-0.05%",
    marginTop:"16px"
  },
  areaText: {
    color:"#2D2A24",
    fontSize: '16px',
    fontWeight: 700,
    margin:"32px 0 8px 0"
  },
  inputDefaultLabel: {
    color:"#94A3B8",
    fontSize:"16px",
    fontWeight:400
  },
  formControl: {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    border: "1px solid #CBD5E1",
    height:"44px",
  },
  menuItems: {
    backgroundColor: '#ffffff',
    borderBottom: "1px solid #CBD5E1",
    color:"#2D2A24",
    fontSize:"14px",
    fontWeight:700,
    '&:hover': {
                background: '#FFFDE7', 
              },
  },
  menuItemsSecond: {
    fontWeight:700,
    backgroundColor: '#ffffff',
    fontSize:"14px",
    color:"#2D2A24",
    '&:hover': {
                background: '#FFFDE7', 
              },
  },
  mapButton: {
    height:"44px",
    backgroundColor: '#F5C502',
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    color:"#2D2A24",
    fontSize: '16px',
    fontWeight: 700,
    textTransform:"capitalize",
    borderRadious:"8px",
    marginTop:"32px",
  },
  submitButton: {
    marginTop:"24px",
    justifyContent:"center",
    height:"44px",
    textTransform:"capitalize",
    backgroundColor: '#F5C502',
    display:"flex",
    alignItems:"center",
    color:"#2D2A24",
    fontSize: '16px',
    borderRadious:"8px",
    fontWeight: 700,
  },
  referralButton: {
    marginTop:"24px",
    justifyContent:"center",
    height:"44px",
    textTransform:"capitalize",
    backgroundColor: '#F5C502',
    display:"flex",
    alignItems:"center",
    color:"#2D2A24",
    fontSize: '16px',
    borderRadious:"8px",
    fontWeight: 700,
    // marginLeft:2,
    marginRight:2
  },
  listItemName: {
    fontWeight:700,
    fontSize:"14px",
    color:"#2D2A24",
  },
  listItemQuantity: {
    fontWeight:400,
    fontSize:"16px",
    color:"#2D2A24",
  },
  itemsMainBox: {
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    width:"100%",
  },
  checkboxContainer: {
    justifyContent:"space-between",
    display:"flex",
    alignItems:"center",
  },
  selectMenu: {
    height: "44px",
    color: "#94A3B8",
    fontSize: "16px",
    fontWeight: 400,
  },
  estimatedItems: {
    color:"#2D2A24",
    fontWeight:400,
    fontSize:"16px",
  },
  estimatedBox: {
    height:"44px",
    justifyContent:"space-between",
    alignItems:"center",
    display:"flex",
    marginTop:"8px",
    padding:"0px 32px"
  },
  totalItems: {
    fontWeight:700,
    fontSize:"16px",
    color:"#2D2A24",
  },
  uploadButtonBox: {
    padding: '8px',
    height:"64px",
    borderRadious:"8px",
    boxShadow: "0px 2px 4px 0px #00000026",
  },
  photoCamera: {
    height: "48px",
    width: "48px",
    borderRadius: "8px",
    backgroundColor: '#F5C502'
  },
  cameraIcon: {
    color: "#2D2A24",
    fontSize: "24px"
  },
  addPictureText: {
    marginLeft: '16px',
    color:"#2D2A24",
    fontSize:"16px",
    fontWeight:400
  },
  pendingImageBox: {
    height: "48px",
    width: "48px",
    borderRadius: "6px",
    backgroundColor: '#FFFDC1'
  },
  defaultImageIcon:{
    color:"#F5C502",
    fontSize: "20px"
  },
  uploadButton: {
    backgroundColor: '#F5C502',
    height:"44px",
    color:"#2D2A24",
    fontSize:"16px",
    fontWeight:700,
    textTransform:"capitalize",
  },
  checkBoxMenu: {
    height: "44px",
  },
  serviceSelect: {
    height:"44px",
    fontSize:"16px",
    fontWeight:700,
  },
  umbrellaImageBox: {
    backgroundColor: "white",
    display: 'flex',
    width: "100%"
  },
  umbrellaImage: {
    width: "375px",
    height: "240px",
    padding: "20px",
    alignSelf: "center"
  },
  addPictureHeadingText: {
    fontSize: "24px",
    fontWeight: 700,
    color: "#2D2A24"
  },
  tableContainerBox: {
    boxShadow: 'none',
    borderRadius: '8px',
    border: '1px solid #CBD5E1',
  },
  addPictureIcon: {
    position: 'absolute',
    right: "4.51px",
    top: "4.51px"
  },
  footerGridBox: {
    height: "auto",
    backgroundColor: '#333',
    color: 'white',
    padding: '20px',
    textAlign: 'center',
    justifyContent: "center",
    alignItems: "center",
    textAlignVertical: "center",
    alignContent: "center"
  },
  photoCamera1: {
    height: "48px",
    width: "48px",
    borderRadius: "8px",
    backgroundColor: '#F5C502'
  },
  footerLogo: {
    width: "190.02px",
    height: "26.47px",
    backgroundColor: "#F5C502",
    borderRadius: 20,
    marginLeft: 10, 
    margin: 5,
    paddingLeft: 1, 
    marginBottom: 5
  },
  uploadButtonBox1: {
    padding: '8px',
    height:"64px",
    borderRadious:"8px",
    boxShadow: "0px 2px 4px 0px #00000026",
  },
  footerView: {
    height: 1, 
    backgroundColor: "#F5C502", 
    paddingHorizontal: 20
  }
};
// Customizable Area End
