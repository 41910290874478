import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
interface Item {
  name: string;
  quantity: number | string;
  checked: boolean;
}
interface Service{
  [x: string]: unknown;
  name: any;
  checked: boolean | undefined;
  id: string,
  type: string,
  checkedIds:boolean,
  attributes: {
      sub_services: any;
      name: string,
      service_offered_name: string,
      created_at: string,
      updated_at: string,
      checked: boolean;
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  open:boolean,
  operationType:string,
  enablePasswordField:boolean,
  password:string,
  token:string,
  searchResult:[],
  searchtext:string,
  selectedResult: {
    status: string;first_name: string; last_name: string; id: number; phone_number:string,email:string 
},
successModal:boolean,
success:boolean,
title:string,
subTitle:string,
enableAdd:boolean,
error:boolean,
errorMessage:string,
emailReg:RegExp,
errorEmail:boolean,
openEditOrg:boolean,
items: Item[];
  selectedItems: string[];
  estimatedTotal: number;
  orgnization:{
    organization_name: string
            address: string
            contact_person: string
            organization_phone: string
            organization_email: string
            created_at: string
            updated_at: string
  };
  orgnizationData:{
    relationships: {
        sub_services: {
            data: [
                {
                    name: any;
                    id: any;
                    sub_service_id: any;
                    type: string,
                    service_offered_name: string,
                    sub_service_name: string,
                    available_beds: boolean
                }
            ]
        }
    }
  },
  allServices:Service[],
  selectedService:[],
  Services:Service[],
  isModalOpen:string,
  checkedData:{ id: string }[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Rolesandpermissions2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiSearchCallId: string="";
  apiEditCallId: string="";
  apiAddCallId: string="";
  apiGetOrgCallId: string="";
  apiGetAllServicesCallId: string="";
  data:string=""
  apiUpdateOrgCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      open:false,
      operationType:"mainForm",
      enablePasswordField:true,
      password:"",
      token:"",
      searchResult:[],
      searchtext:"",
      selectedResult: {first_name: "", last_name: "", id: 0,status:"", phone_number:"",email:"" },
      successModal:false,
      success:false,
      title:"",
      subTitle:"",
      enableAdd:false,
      error:false,
      errorMessage:"",
      emailReg:new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/),
      errorEmail:false,
      openEditOrg:false,
      selectedItems:[],
      items: [
        { name: 'Syringes', quantity: 30, checked: false },
        { name: 'Cotton Balls', quantity: 60, checked: false },
        { name: 'Smoking Pipes', quantity: 50, checked: false },
        { name: 'Fit Kits', quantity: '#', checked: false },
        { name: 'Orange Tops', quantity: '#', checked: false },
        { name: 'Cookers', quantity: 20, checked: false },
        { name: 'Elastic Tie (Tourniquet)', quantity: '#', checked: false },
        { name: 'Sniffing Straws', quantity: '#', checked: false },
        { name: 'Gauze', quantity: 40, checked: false },
        { name: 'Band-aid', quantity: '#', checked: false },
        { name: 'Sterile Water', quantity: '#', checked: false },
      ],
      estimatedTotal: 0,
      orgnization:{
                organization_name: "",
                address: "",
                contact_person: "",
                organization_phone: "",
                organization_email: "",
                created_at: "",
                updated_at: ""
      },
      orgnizationData:{
        relationships: {
          sub_services: {
              data: [
                  {
                    type: "",
                    service_offered_name: "",
                    sub_service_name: "",
                    available_beds: false,
                    sub_service_id: '',
                    name: '',
                    id: ''
                  }
              ]
          }
      }
      },
      allServices:[],
      selectedService:[],
      Services:[],
      isModalOpen:"",
      checkedData:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) { switch (apiRequestCallId) {
      case this.apiSearchCallId:
        this.handleSearchResponse(responseJson, errorReponse);
        break;
  
      case this.apiEditCallId:
        this.handleWorkerResponse(
          responseJson, 
          "Edit Outreach Worker", 
          "The Outreach Worker information has been edited successfully."
        );
        break;

        case this.apiAddCallId:
          this.handleWorkerResponse(
            responseJson, 
            "Add Outreach Worker", 
            "The Outreach Worker information has been added successfully."
          );
        break;
        case this.apiGetOrgCallId:
          this.setState({orgnization:responseJson.data.attributes,orgnizationData:responseJson})
        
        break;
        case this.apiGetAllServicesCallId:
          
          this.setState({allServices:responseJson.data,},()=>this.addCheckedFieldToSubServices())
        
        break;
      
        case this.apiUpdateOrgCallId:
          this.handleOrganisationResponse(
            responseJson, 
            "Edit Organization", 
            "The Organization information has been edited successfully."
          );
          this.setState({openEditOrg:false})
        this.getOrganiszation()
        break;
        
      default:
    
        break;
    }}
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
 this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }
  handleClose=()=>{
    this.setState({open:false, searchtext:"",searchResult:[],selectedResult:{first_name: "", last_name: "", id: 0,status:"", phone_number:"",email:"" }})
  }
  openModal=()=>{
    this.setState({open:true})
  }
 
  handleCloseOrg=()=>{
    this.setState({openEditOrg:false})
  }
 
  addClicked=()=>{
    this.setState({open:false,operationType:"AddForm",searchResult:[],enableAdd:false,selectedResult:{first_name: "", last_name: "", id: 0,status:"", phone_number:"",email:"" }})
  }
  editClicked=()=>{
    this.setState({open:false,operationType:"EditForm",searchResult:[]})
  }
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };
  setPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      password: event.target.value,
    });
  };
  goback=(page:string)=>{
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), page);
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }
  searchFilterFunction = (text: string) => {
    this.setState({searchtext:text})
    const header = {
      token:this.state.token
    }



    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSearchCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchAPiEndPoint+text
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodapi
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  getToken = async () => {
    const token = await getStorageData("token")
    this.setState({token:token},()=>[this.getOrganiszation()])
  };
  selectResult = (item:  { first_name: string; last_name: string; id: number; status: string, phone_number:string, email:string })=>{
   this.setState({selectedResult:item})
  }
  gobackIn = ()=>{
    this.setState({operationType:"mainForm",selectedResult:{first_name: "", last_name: "", id: 0,status:"", phone_number:"",email:"" },searchtext:""})
   }
   clear=()=>{
    this.setState({searchtext:"",searchResult:[],selectedResult:{first_name: "", last_name: "", id: 0,status:"", phone_number:"",email:"" }})
   }
   editWorker = () => {
    const header = {
      "Content-Type": "application/json",
      token:this.state.token
    }

    const worker1 = {
      first_name: this.state.selectedResult.first_name,
      last_name: this.state.selectedResult.last_name,
      status: this.state.selectedResult.status,
      phone_number: this.state.selectedResult.phone_number,
      email: this.state.selectedResult.email
    }

    const httpBody = {
      worker: worker1
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEditCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editWorker+this.state.selectedResult.id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  addWorker = () => {
    const header = {
      "Content-Type": "application/json",
      token:this.state.token
    }

    const worker1 = {
      first_name: this.state.selectedResult.first_name,
      last_name: this.state.selectedResult.last_name,
      status: this.state.selectedResult.status,
      phone_number: this.state.selectedResult.phone_number,
      email: this.state.selectedResult.email,
      password:this.state.password
    }

    const httpBody = {
      worker: worker1
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addWorker
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  handleChangePhone = (event:React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState((prevState) => ({
      selectedResult: {
        ...prevState.selectedResult, 
        phone_number: event.target.value, error:false
      },
    }))
    if (/^\d+$/.test(value)) {
      this.setState({
        error: false,
        errorMessage: ''
      });
    } else {
      this.setState({
        error: true,
        errorMessage: 'Only numeric values are allowed'
      });
    }
  };
  handleBlur(){
    
    if(this.state.selectedResult.email.length<=0 || !this.isValidEmail(this.state.selectedResult.email)){
      this.setState({errorEmail:true})
    }
  }
  isValidEmail(email: string) {
    return this.state.emailReg.test(email);
  }
  
  handleWorkerResponse(responseJson: { message: string; errors: string }, title: any, successMessage: any) {
    if (responseJson.message) {
      this.gobackIn();
      this.setState({
        successModal: true,
        success: true,
        title: title,
        subTitle: successMessage
      });
    } else {
      this.setState({
        successModal: true,
        success: false,
        title: title,
        subTitle: responseJson.errors[0]
      });
    }
  }
  handleSearchResponse(responseJson: { workers: any; }, errorReponse: any) {
    if (responseJson.workers) {
      this.setState({ searchResult: responseJson.workers, enableAdd: false });
    } else {
      this.setState({
        searchResult: [],
        enableAdd: true,
        selectedResult: {
          first_name: "",
          last_name: "",
          id: 0,
          status: "",
          phone_number: "",
          email: ""
        }
      });
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }

  handleOrganisationResponse(responseJson: { message: string; errors: string }, title: any, successMessage: any) {
    if (responseJson) {
      this.gobackIn();
      this.setState({
        successModal: true,
        success: true,
        title: title,
        subTitle: successMessage
      });
    } else {
      this.setState({
        successModal: true,
        success: false,
        title: title,
        subTitle: "Please try again later"
      });
    }
  }
  
 
  handleToggle = (selectedservice: Service, selectedsubService: Service) => {
    const toggleSubservice = (
      subservice: { checked: boolean; id: string | null; destroy?: boolean },
      selectedsubService: Service
    ) => {
     
      if (subservice.id && subservice.id === selectedsubService.id) {
        return {
          ...subservice,
          checked: !subservice.checked,
          destroy: subservice.checked ? true : false,
        };
      }
  
      if (!subservice.id && subservice === selectedsubService) {
        return {
          ...subservice,
          checked: !subservice.checked,
        };
      }
  
    
      return subservice;
    };
  
    const updateService = (service: Service, selectedservice: Service) => {
      if (selectedservice.id !== service.id) return service;
  
      return {
        ...service,
        attributes: {
          ...service.attributes,
          sub_services: service.attributes.sub_services.map((subservice: { checked: boolean; id: string | null; destroy?: boolean; }) =>
            toggleSubservice(subservice, selectedsubService)
          ),
        },
      };
    };
  
    this.setState((prevState) => ({
      allServices: prevState.allServices.map((service) =>
        updateService(service, selectedservice)
      ),
    }));
  };
  getOrganiszation = () => {
   
    const header = {
      token:this.state.token
    }



    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetOrgCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOrgApiENdPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodapi
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  getAllServices = () => {
   
    const header = {
      token:this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAllServicesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllServices
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodapi
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
 
  
  addCheckedFieldToSubServices() {
    if(this.state.orgnizationData.relationships?.sub_services?.data.length>0){

  
    const subServiceIds = 
    this.state.orgnizationData.relationships?.sub_services?.data?.map(
      (subService) => subService.sub_service_id
    ) ;
    const idupdate = 
    this.state.orgnizationData.relationships?.sub_services?.data?.map(
      (subService) => subService
    ) ;
    this.setState(
    (prevState) => ({
      allServices: prevState.allServices.map((service) => ({
        ...service,
        attributes: {
          ...service.attributes,
          sub_services: service.attributes.sub_services.map(
            (subService: {
              name: any; id: any; checked?: boolean; destroy?: boolean 
}) => {
              const isChecked = subServiceIds.includes(subService.id);
              const subServiceId =  subServiceIds.find((id) => id === subService.id)
               
                const subid =  idupdate.find((id) => id.sub_service_name === subService.name)
              return {
                ...subService,
                checked: isChecked,
                destroy: false, 
                sub_service_id: subServiceId || subService.id,
                id:subid?.id || null
               
              };
            }
          ),
        },
      })),
    })
    
  );
}else{
  this.setState(
    (prevState) => ({
      allServices: prevState.allServices.map((service) => ({
        ...service,
        attributes: {
          ...service.attributes,
          sub_services: service.attributes.sub_services.map(
            (subService: {
              name: any; id: any; checked?: boolean; destroy?: boolean 
}) => {
             
              return {
                ...subService,
                checked: false,
              
                sub_service_id: subService.id,
             
               
              };
            }
          ),
        },
      })),
    })
    
  );
}
    this.setState({openEditOrg:true})
  }

   getUpdatedSubServices(allServices: any[], organizationData: { relationships: { sub_services: { data: any; }; }; }) {
    const organizationSubServices = organizationData.relationships.sub_services.data;

    const updatedSubServices = allServices.flatMap((service) =>
      service.attributes.sub_services
        .map((subService: { id: any; checked: any; sub_service_id: any; }) => {
          const orgSubService = organizationSubServices.find(
            (orgSub: { id: any; }) => orgSub.id === subService.id
          );
  
          if (orgSubService && !subService.checked) {
            return { id: subService.id, _destroy: true };
          }
  
          if (!orgSubService && subService.checked) {
            return { sub_service_id: subService.sub_service_id };
          }
  
          return null;
        })
        .filter(Boolean) 
    );
  
    return updatedSubServices;
  }
updateOrganization=()=>{
  const updatedSubServices = this.getUpdatedSubServices(this.state.allServices, this.state.orgnizationData);


 const header = {
  "Content-Type": "application/json",
  token:this.state.token
}
    const httpBody = {
      organization: {
        contact_person: this.state.orgnization.contact_person,
        organization_phone: this.state.orgnization.organization_phone,
        organization_email: this.state.orgnization.organization_email,
        organization_sub_services_attributes: 
        updatedSubServices
        
      }
    }


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateOrgCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateOrgEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  
}
setData = (id: string) => {
  this.setState((prevState) => {
    const idExists = prevState.checkedData.some((checkedItem) => checkedItem.id === id);

    const updatedCheckedData = idExists
      ? prevState.checkedData.filter((checkedItem) => checkedItem.id !== id)  
      : [
          ...prevState.checkedData,
          { id },  
        ];

    return {
      checkedData: updatedCheckedData,
    };
  });
}

  // Customizable Area End
}
