import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { SelectChangeEvent } from '@mui/material/Select';
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import React from 'react';

interface HarmReductionForm {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      event_name: string;
      worker_id: number;
      created_at: string;
      latitude: number;
      longitude: number;
      worker: string;
      community_supply_items: CommunitySupplyItem[];
    };
  };
}

interface CommunitySupplyItem {
  id: string;
  type: string;
  attributes: {
    id: number;
    supplied_item_id: number;
    count: number;
    value: number | null;
    created_at: string;
    supply_item_name: string;
    supply_item_type: string;
  };
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
interface Item {
  value: string;
  count: string | number;
  id: string;
  name: string;
  quantity: number | string;
  checked: boolean;
  input_type:string
}
// Customizable Area End

interface S {
  // Customizable Area Start
  selectedService: string;
  items: Item[];
  selectedItems: string[];
  estimatedTotal: number;
  open: boolean;
  selectedFiles: File[];
  labelTextFirst: string;
  labelTextSecond: string;
  NumberOfItems: string;
  eventName:string;
  NarcanTraining:string
  selectedData:[],
  successModal:boolean,
  success:boolean,
  title:string,
  subTitle:string,
  removeImg:boolean,
  uploadedImg:number,
  token:string,
  image_ids: number[],
  formId: string;
  formType: string;
  getHarmFormDetails: HarmReductionForm;
  showEdit: boolean;
  isDropdownOpen:number;
  openView:boolean,
  imageView:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class HarmReductionFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getHarmReductionListApiCallId: string="";
  apiharmreductionCallId: string="";
  uploadImageCallIdHarm: string="";
  apiCallIdGetHarmFormDetails: string = "";
  apiCallIdEditHarmForm: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedService: "",
      selectedItems:[],
      items: [],
      estimatedTotal: 0,
      open: false,
      selectedFiles: [],
      labelTextFirst: "Items",
      labelTextSecond: "List Items",
      NumberOfItems: "Number of Items:",
      eventName:"",
      NarcanTraining:"",
      selectedData:[],
      successModal:false,
      title:"",
      subTitle:"",
      success:false,
      removeImg:false,
      uploadedImg:0,
      token:"",
      image_ids: [],
      formId: "",
      formType: "",
      getHarmFormDetails: {
        data: {
          id: "",
          type: "",
          attributes: {
            id: 0,
            event_name: "",
            worker_id: 0,
            created_at: "",
            latitude: 0,
            longitude: 0,
            worker: "",
            community_supply_items: []
          }
        }
      },
      showEdit: false,
      isDropdownOpen:0,
      openView:false,
      imageView:""
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
   
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) { switch (apiRequestCallId) {
      case this.getHarmReductionListApiCallId:
        this.setState({items:responseJson.items},()=>this.addChecked())
        
        break;

        case this.apiharmreductionCallId:
          if(responseJson.message){
            this.setState({title:"Harm Reduction Supplies Distribution", subTitle:"Form Submitted",success:true,successModal:true})
          }
          else{
            this.setState({title:"Harm Reduction Supplies Distribution", subTitle:"Error Occured. Please try again later.",success:false,successModal:true})
          }
      
        break;
        case this.uploadImageCallIdHarm:
            this.handleImageUploadHarm(responseJson);
          break;

          case this.apiCallIdGetHarmFormDetails:
              this.handleGetHarmFormDetails(responseJson)
  break;

  case this.apiCallIdEditHarmForm :
    console.log("Form Updated")
          break;

      default:
        console.warn("Unhandled API request call ID:", apiRequestCallId);
            break;
    }}
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken()
    this.getHarmReductionSuppliesList()
    const formid = await getStorageData("formId");
    const formtype = await getStorageData("formType");
    const edit = await getStorageData("showEdit");
    
    this.setState({
      formId: formid,
      formType: formtype,
      showEdit: edit
    });
    this.getFormDetails()
  };

  handleGetHarmFormDetails = (responseJson: HarmReductionForm) => {
    this.setState({ getHarmFormDetails: responseJson }, () => {
      console.log("lolllllllllllllll", this.state.getHarmFormDetails, this.state.formId, this.state.showEdit)
      if (this.state.formId && this.state.showEdit) {
        const apiItems: Item[] = this.state.getHarmFormDetails.data.attributes.community_supply_items.map((item) => ({
          id: item.id.toString(),
          name: item.attributes.supply_item_name,
          count: item.attributes.count.toString(), 
          quantity: (item.attributes.value ?? 0).toString(), 
          value: (item.attributes.value ?? 0).toString(), 
          checked: true, 
          input_type: item.attributes.supply_item_type,
        }));
  
        const mergedItems: Item[] = [
          ...apiItems,
          ...this.state.items.filter(
            (item) => !apiItems.some((apiItem) => apiItem.id === item.id)
          ),
        ];
        this.setState({ items: mergedItems });
      }
    });
  }

  getFormDetails = async () => {

    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
       token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallIdGetHarmFormDetails = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_custom_forms/update_submitted_forms/${this.state.formId}?form_type=${this.state.formType}`
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.apiCallIdGetHarmFormDetails;
};

  handleToggle = (index: number) => {
    
    const { items } = this.state;
    const selectedItems = items?.filter((item) => item.checked)
    .map((item) => item.name);

  this.setState({ items, selectedItems }, this.updateEstimatedTotal);
    items?.forEach((item, idx) => {
      if (idx === index) {
        item.checked = !item.checked; 
      }
    });
    
  
    

    
  };

  handleSelectChange = (event: SelectChangeEvent<string[]>) => {
    const selectedItems = event.target.value as string[];
    this.setState({ selectedItems }, this.updateEstimatedTotal);
  };

  updateEstimatedTotal = () => {
    const { items } = this.state;

    const count = items?.reduce(
      (total, item) => (item.checked ? total + Number(item.count) : total),
      0
    );
  
    this.setState({ estimatedTotal: count });
  };

  

  
 

   getHarmReductionSuppliesList = async () => {

    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getHarmReductionListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getHarmReductionSupplies
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  addChecked=()=>{
    this.setState(prevState => ({
      items: prevState.items?.map(item => ({
        ...item,
        checked: false ,
          supplied_item_id: item.id,
          count: 0
      
      }))
    }));
  }
  checkInt=(item:Item,value:React.ChangeEvent<HTMLInputElement>)=>{
    const newValue = value.target.value; 
    if(item.input_type==="integer_field"){
      this.updateSingleItemChecked(item.id,value.target.value)
    }else{
      this.setState({NarcanTraining:newValue})
    }
  }
  updateSingleItemChecked = (id: string, value:string) => {
   
    const newValue = value;
    this.setState(prevState => ({
      items: prevState.items?.map(item =>
        item.id === id 
          ? { ...item, count: newValue} 
          : item 
      ),
    }),()=>this.updateEstimatedTotal);
    const { items } = this.state 
     items?.reduce((acc, item) => {
      return item.checked ? acc + Number(item.count) : acc;
    }, 0);
    
  };
  
  
  editHarmReductionForm = async () => {
    await removeStorageData("showEdit");
  
    const checkedItems = this.state.items
      .filter((obj) => obj.checked === true)
      .map((obj) => {
        if (obj.name === 'Narcan training') {
          return { supplied_item_id: parseInt(obj.id, 10), value: this.state.NarcanTraining };
        }
        return { supplied_item_id: parseInt(obj.id, 10), count: parseInt(String(obj.count), 10) };
      });
  
    const existingItems = this.state.getHarmFormDetails.data.attributes.community_supply_items.map((item) => ({
      supplied_item_id: item.attributes.supplied_item_id,
      count: item.attributes.count,
      value: item.attributes.value,
    }));
  
    const mergedItemsMap = new Map<number, { supplied_item_id: number; count?: number; value?: number }>();
    checkedItems.forEach((item) => {
      const normalizedItem = {
        ...item,
        value: item.value ? parseFloat(String(item.value)) : undefined,
      };
      mergedItemsMap.set(normalizedItem.supplied_item_id, normalizedItem);
    });
    
    existingItems.forEach((item) => {
      const normalizedItem = {
        ...item,
        value: item.value !== null ? item.value : undefined, 
      };
      if (!mergedItemsMap.has(normalizedItem.supplied_item_id)) {
        mergedItemsMap.set(normalizedItem.supplied_item_id, normalizedItem);
      }
    });
    const mergedItems = Array.from(mergedItemsMap.values());
  
  
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token"),
    };
  
    const data = {
      event_name: this.state.getHarmFormDetails.data.attributes.event_name,
      lat: this.state.getHarmFormDetails.data.attributes.latitude,
      lng: this.state.getHarmFormDetails.data.attributes.longitude,
      image_ids: this.state.image_ids,
      community_supply_items_attributes: mergedItems,
    };
  
    const httpBody = { data };

  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apiCallIdEditHarmForm = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/update_submitted_forms/${this.state.formId}?form_type=${this.state.formType}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return this.apiCallIdEditHarmForm;
  };
  
  
  submitHarmReduction = async () => {
    await removeStorageData("showEdit")
    const checkedItems = this.state.items
    .filter((obj) => obj.checked === true)
    .map((obj) => {
      if (obj.name === 'Narcan training') {
        return { supplied_item_id: obj.id, value: this.state.NarcanTraining };
      }
      return { supplied_item_id: obj.id, count: obj.count };
    });
  

    const header = {
      "Content-Type": "application/json",
      token:await getStorageData("token"),
    }

    const data = {
      event_name: this.state.eventName,
        lat: 28.6336048088,
        lng: 77.2050245803,
        image_ids: this.state.image_ids,
        // narcan_training: this.state.NarcanTraining,
        community_supply_items_attributes: checkedItems
    }

    const httpBody = {
      data: data
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiharmreductionCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addHarmReductionEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.formAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  setEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedService = event.target.value;
  
    if (this.state.showEdit) {
      if (this.state.getHarmFormDetails) {
        this.setState((prevState) => ({
          getHarmFormDetails: {
            ...prevState.getHarmFormDetails,
            data: {
              ...prevState.getHarmFormDetails.data,
              attributes: {
                ...prevState.getHarmFormDetails.data.attributes,
                event_name: selectedService, 
              },
            },
          },
        }));
      }
    } else {
      this.setState({ eventName: selectedService });
    }
  };
  goback=async (page:string)=>{
    await removeStorageData("showEdit")
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), page);
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }
  handleCloseModalharm = () => {

    this.setState({ successModal: false });
    this.goback("LandingPage")
  };
  handleOpenHarm = () => {
    this.setState({ open: true });

  };
  handleCloseHarm = () => {
    this.setState({ open: false, });
  };
  handleCloseHarmView = () => {
    this.setState({openView:false });
  };
  handleFileChangeHarm = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
  
    if (files) {
      this.setState((prevState) => ({
        selectedFiles: [...prevState.selectedFiles, ...Array.from(files)],
      }));
    }
  };
  handleRemoveFile = (index: number) => {
    this.setState({isDropdownOpen:0})
    this.setState((prevState) => ({
      selectedFiles: prevState.selectedFiles.filter((_, i) => i !== index),
    }));
  };
  uploadImagesHarm = () => {
    const { selectedFiles } = this.state;
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("blob[]", new File([file as any], file.name, { type: "image/png" })); 
    });
    const headersHarm = {
      token: this.state.token,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.uploadImageCallIdHarm = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addPictureEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headersHarm)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.formAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getToken = async () => {
    const token = await getStorageData("token")
    this.setState({token:token})
  };
  handleCountChange = (event: { target: { value: any; }; }, itemName: string) => {

    const updatedItems = this.state.items.map((item) =>

      item.name === itemName ? { ...item, count: event.target.value } : item
    );
    this.setState({ items: updatedItems },
      ()=>this.updateEstimatedTotal());
  };

  viewImageHarm(file: File,index:number){
    this.setState({isDropdownOpen:index+1})
    const reader = new FileReader();
      reader.onload = () => {
         this.setState({imageView:reader.result as string})
      };
      reader.readAsDataURL(file); 
  }
  handleImageUploadHarm = (responseJson:{message:string,blobs:any}) => {
    if (responseJson && responseJson.message === "Images uploaded successfully") {  
      const uploadedBlob1 = responseJson.blobs;
      const uploadedImageId1 = responseJson.blobs.map((blob: { id: any; }) => blob.id)

      this.setState((prevState) => ({
        image_ids:  uploadedImageId1,
        selectedFiles: prevState.selectedFiles.map((file) =>
          file.name === uploadedBlob1.filename ? { ...file, status: "uploaded" } : file
        ) 
      }));
      this.setState({open:false})
    } 
    

  }
 
  // Customizable Area End
}
