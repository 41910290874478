import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    // Customizable Area Start
    navigation: string;
    id: string;
    // Customizable Area End
}

// Customizable Area Start
interface SubData {
  id:number,
  date:Date,
  type:string
}
interface Org{
  
    id: string;
    attributes:{
      organization_name:string,
      organization_info:{attributes:{
        address:string,contact_person:string,organization_phone:string,organization_email:string
      }}
    }
  
}
interface NewOrg{
  id:string,
  organization_name:string
}
interface OrgDetails{
  organization:{
    id:string,
    attributes:{
      organization_name:string,
      contact_person:string,
      organization_phone:string,
      address:string
    }
  },
  is_bed_availble:boolean
}
interface Referral{id:string,attributes:{organization_info:{attributes:{
  address:string,contact_person:string,organization_phone:string,organization_email:string
}},organization_id:number,organization_name:string,created_at:string,sub_service_name: string;status:string}}
// Customizable Area End

interface S {
    // Customizable Area Start
    token:string;
    orgnisation:Referral[];
    isToggled:boolean,
    selectedFilter: string,
    selectedService: Org,
    isServicesOpen: boolean,
    data:SubData[],
    selectedDate: Date | null;
    referralform:Referral[],
    openUpdateModal:boolean,
    selectedReferral:{id:string,attributes:{sub_service_id:string,organization_info:{attributes:{
      address:string,contact_person:string,organization_phone:string,organization_email:string
    }},
        status: string;organization_id:number,organization_name:string,created_at:string,sub_service_name: string;
}},
    openEditModal:boolean,
    allOrg:Org[],
    openCancelModal:boolean,
    reason:string,
    referralorg:NewOrg[],
    selectedRefOrg:NewOrg,
    refOrgDetails:OrgDetails,
    updateRef:boolean,
    successModal:boolean,
    Title:string,
    subTitle:string,
    success:boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: number;
    // Customizable Area End
}

export default class ReferralsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getorganisationListApiCallId: string="";
  apiGetOrgCallId: string="";
  apiGetreferralCallId: string="";
  apiGetshowreferralCallId: string="";
  apiCancelreferralCallId: string="";
  apiGetreferralORGDetailCallId: string="";
  apiUpdatereferralCallId: string="";
  
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token:"",
            orgnisation:[],
            isToggled: false,
            selectedFilter:"All",
            selectedService:{
           
                id:"",
                attributes: {
                  organization_name: "",
                  organization_info:{attributes:{
                    address:"",contact_person:"",organization_phone:"",organization_email:""
                  }},
                }
              
            },
            data: [],
              isServicesOpen: true,
              selectedDate: new Date(),
              referralform:[
              ],
              openUpdateModal:false,
              selectedReferral:{
                id:"",
                attributes: {
                  sub_service_id:"",
                  organization_info:{attributes:{
                    address:"",contact_person:"",organization_phone:"",organization_email:""
                  }},
                  organization_id: 0,
                  organization_name: "",
                  created_at: "",
                  sub_service_name: "",status:""
                },
               
              },
              openEditModal:false,
              allOrg:[],
              openCancelModal:false,
              reason:"",
              referralorg:[],
              selectedRefOrg:{
                id: "",
                organization_name: ""
              },
              refOrgDetails:{
                organization:{
                  id:"",
                  attributes:{
                    organization_name:"",
                    contact_person:"",
                    organization_phone:"",
                    address:""
                  }
                },
                is_bed_availble:false,
               
              },
              updateRef:false,
              successModal:false,
              Title:"",
              subTitle:"",
              success:false
            // Customizable Area End
        };

        // Customizable Area Start
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
      
            const responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
      
            if (apiRequestCallId && responseJson) {
              switch (apiRequestCallId) {
                case this.apiGetreferralCallId:
                  this.setState({ orgnisation: responseJson.data });
                  break;
            
                case this.apiGetOrgCallId:
                  this.getsearchOrg(responseJson);
                  break;
            
                case this.apiGetshowreferralCallId:
                  this.setState({
                    openUpdateModal: true,
                    selectedReferral: responseJson.data,
                  });
                  this.getOrganisationList();
                  break;
            
                case this.apiCancelreferralCallId:
                  this.cancelResponsemanage(responseJson)
                 
                  break;
            
                case this.getorganisationListApiCallId:
                  this.setState({ referralorg: responseJson.organizations });
                  break;
            
                case this.apiGetreferralORGDetailCallId:
                  this.setState({ refOrgDetails: responseJson });
                  break;
            
                case this.apiUpdatereferralCallId:
                  this.updateResponsemanage(responseJson)
                 
                  break;
            
                default:
                  break;
              }
              
            }
          
          }
        // Customizable Area End
    }

    // Customizable Area Start
    cancelResponsemanage(responseJson: { message: string; errors: { message: string; }[]; }){
      this.getFilterResult()
      if (responseJson.message) {
        this.setState({
          openCancelModal: false,
          successModal: true,
          Title: "Cancel Referral",
          subTitle: "The Referral has been canceled successfully",
          success: true,
        });
      } else {
        this.setState({
          openCancelModal: false,
          successModal: true,
          Title: "Edit Referral",
          subTitle: responseJson.errors[0].message,
          success: false,
        });
      }
    }
    updateResponsemanage(responseJson: { message: string; }){
      this.getFilterResult()
      this.setState({ openEditModal: false, updateRef: false });
      if (responseJson.message) {
        this.setState({
          openEditModal: false,
          updateRef: false,
          successModal: true,
          Title: "Edit Referral",
          subTitle: "The Referral has been edited successfully",
          success: true,
        });
      } else {
        this.setState({
          openEditModal: false,
          updateRef: false,
          successModal: true,
          Title: "Edit Referral",
          subTitle: "Please try again later",
          success: false,
        });
      }
    }
    async componentDidMount() {
        this.getToken()
      
      }
      getToken = async () => {
        const token = await getStorageData("token")
        this.setState({token:token},()=>   this.getOrganiszationAll())
      };
    getOrganisationList = () => {

        const header = {
          "Content-Type": configJSON.getUserListApiContentType,
          token: this.state.token
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getorganisationListApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.orgnisationListApiEndPoint
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getUserListApiMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      };
      
      goback=(page:string)=>{
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), page);
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
      }
      handleToggle = () => {
        this.setState((prevState) => ({
          isToggled: !prevState.isToggled,
        }));
      }
    
    handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedFilter: event.target.value },()=>{
      if(event.target.value==="All"){
        this.getFilterResult()
      }
    });
    
    };
       
    handleServiceToggle = () => {
      this.setState((prevState) => ({
        isServicesOpen: !prevState.isServicesOpen,
      }));
    }
    handleServiceChange = ( value: {id:string, attributes: {  organization_info:{attributes:{
      address:string,contact_person:string,organization_phone:string,organization_email:string
    }}, organization_name: string; };  }) => {
      this.setState({ selectedService: value },()=>this.getFilterResult());
    };
 
  
  handleDateChange = (date: Date | null):void => {
      this.setState({ selectedDate: date },()=>this.getFilterResult());
      
    };
    getOrganiszationAll = () => {
   this.getFilterResult()
      const header = {
        token:this.state.token
      }
    
    
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.apiGetOrgCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getReferralsApiEndpoint
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodapi
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
    
      return true;
    };
  
    selectedRefDetails=(id:string)=>{
   
      const header = {
        token:this.state.token
      }
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.apiGetshowreferralCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.showReferralApiEndpoint+id
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodapi
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
    
      return true;
    };
    cancelReferral=()=>{
   
      const header = {
        token:this.state.token
      }
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.apiCancelreferralCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.cancelReferralApiEndpoint+this.state.selectedReferral.id+"&cancellation_reason="+this.state.reason
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.updateFormAPiMethod
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
    
      return true;
    };
    getFilterResult= () => {
    
      const { selectedFilter, selectedDate, selectedService } = this.state;

       let endpointUrl = configJSON.getReferralsApiEndpoint;

       let queryParams: string[] = [];
       if (selectedFilter === 'Date' && selectedDate) {
        const formattedDate = selectedDate.toLocaleDateString('en-GB'); 
        queryParams.push(`date=${formattedDate}`);
       }

       if (selectedFilter === 'Organization' && selectedService) {
        queryParams.push(`organization_id=${selectedService.id}`);
       }

       if (queryParams.length > 0) {
        endpointUrl += `?filter_by=${selectedFilter.toLowerCase()}&` + queryParams.join('&');
      }

      const header = {
        token:this.state.token
      }
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.apiGetreferralCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endpointUrl
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodapi
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
    
      return true;
    };
    handleSelectChange = (event: NewOrg) => {
      
      this.setState({ selectedRefOrg:event },()=>this.getBedAvaialbility());
    };
    getBedAvaialbility= () => {
      const header = {
        token:this.state.token
      }
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.apiGetreferralORGDetailCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getBedData+this.state.selectedReferral.attributes.sub_service_id+"&id="+this.state.selectedRefOrg.id
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodapi
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
    
      return true;
    };
    updateReferral=()=>{
   
      const header = {
        token:this.state.token
      }
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.apiUpdatereferralCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateReferralApiEndpoint+this.state.selectedReferral.id+"?organization_id="+this.state.refOrgDetails.organization.id
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.updateFormAPiMethod
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
    
      return true;
    };
    getsearchOrg(response: { data: any[]; }){
      const uniqueOrganizations: any[] = [];
    const seenOrganizations = new Set();
    response.data.forEach((item) => {
      const orgName = item.attributes.organization_name;
      if (!seenOrganizations.has(orgName)) {
        seenOrganizations.add(orgName);
        uniqueOrganizations.push(item);
      }
    });
    this.setState({ allOrg: uniqueOrganizations})
    }
    // Customizable Area End
}
