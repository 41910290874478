import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isToggled: boolean;
  selectedFilter: string;
  startDate:  Date | undefined;
  endDate:  Date | undefined;
  selectedService:string;
  open:boolean;
  activePicker: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MapsController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      isToggled: false,
      selectedFilter: 'All',
      startDate: new Date(),
      endDate: new Date(),
      selectedService: "",
      open: false,
      activePicker:"start",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleToggle = () => {
    this.setState((prevState) => ({
      isToggled: !prevState.isToggled,
    }));
  }
  
  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedFilter: event.target.value });
  };

  handleStartDateChange = (startDate: Date | null) => {
    this.setState({ startDate: startDate ?? undefined }); 
    this.setState({activePicker:"dateRange"});
  };
  
  handleEndDateChange = (endDate: Date | null) => {
    this.setState({ endDate: endDate ?? undefined }); 
    this.setState({activePicker:"dateRange"});
  };

  handleActivePicker = (val: string) =>{
    this.setState({activePicker:val})
  }

  // Customizable Area End
}
