import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
interface Service {service_type_name:string,key:string}
interface TeamMember {
  id: number,
  first_name: string,
  last_name: string
}
interface Location {
  lattiutude: number,
  longitude: number,
  icon_url: string,
  form_type: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isToggled: boolean;
  selectedFilter: string;
  startDate:  Date | undefined;
  endDate:  Date | undefined;
  selectedService:Service;
  selectedTeamMember:TeamMember;
  open:boolean;
  activePicker: string;
  token:string,
  serviceList:Service[],
  teamMember:TeamMember[],
  locationList:Location[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MapsController extends BlockComponent<Props, S, SS> {
  
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      isToggled: false,
      selectedFilter: 'All',
      startDate: new Date(),
      endDate: new Date(),
      selectedService: {
        service_type_name: "",
        key: ""
      },
      selectedTeamMember:{
        id: 0,
        first_name: "",
        last_name: ""
      },
      open: false,
      activePicker:"start",
      token:"",
      serviceList:[],
      teamMember:[],
      locationList:[]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) { 
      switch (apiRequestCallId) {
      case this.apiGetLocationCallId:
        this.setState({locationList:responseJson.data})
        break;
        case this.apiGetMemberCallId:
          this.setState({teamMember:responseJson.data})
          break;
          case this.apiGetServiceCallId:
            this.setState({serviceList:responseJson.data})
            break;
      default:
    
        break;
    }}
    // Customizable Area End
  }

  // Customizable Area Start
  apiGetMemberCallId: string="";
  apiGetServiceCallId: string="";
  apiGetLocationCallId: string="";
  async componentDidMount() {
    super.componentDidMount();
 this.getToken();
   
  }
  handleToggle = () => {
    this.setState((prevState) => ({
      isToggled: !prevState.isToggled,
    }));
  }
  
  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedFilter: event.target.value },()=>{
      if(event.target.value==="All"){
        this.getLocation()
      }
    });
   
  };
  handleService = (event:Service) => {
    this.setState({ selectedService: event },()=>this.getLocation());
  };
  handleTeamMember = (event:TeamMember) => {
    this.setState({ selectedTeamMember: event },()=>this.getLocation());
  };

  handleStartDateChange = (startDate: Date | null) => {
    this.setState({ startDate: startDate ?? undefined }); 
    this.setState({activePicker:"dateRange"});
  };
  
  handleEndDateChange = (endDate: Date | null) => {
    this.setState({ endDate: endDate ?? undefined },()=>this.getLocation()); 
    this.setState({activePicker:"dateRange"});
  };

  handleActivePicker = (val: string) =>{
    this.setState({activePicker:val})
  }
  getToken = async () => {
    const token = await getStorageData("token")
    this.setState({token:token},()=>this.getLocation())
  };
  getLocation = () => {
   this.getMember()
   this.getService()
    const { selectedFilter, startDate, endDate, selectedService, selectedTeamMember } = this.state;

    let endpointUrl = configJSON.getLocationsApiENdPoint;

    let queryParams: string[] = [];
    if (selectedFilter === 'Date Range' && startDate && endDate) {
     const start = startDate.toLocaleDateString('en-GB'); 
     const end = endDate.toLocaleDateString('en-GB'); 
     queryParams.push(`date_range&start_date=${start}&end_date=${end}`);
    }

    if (selectedFilter === 'Service Type' && selectedService) {
     queryParams.push(`service_type&service_type_key=${selectedService.key}`);
    }
    if (selectedFilter === 'Team Member' ) {
      queryParams.push(`team_member&worker_id=${selectedTeamMember.id}`);
     }
    if (queryParams.length > 0) {
     endpointUrl += `?filter_by=` + queryParams.join('&');
   }
    const header = {
      token:this.state.token
    }



    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetLocationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpointUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  getMember = () => {
   
    const header = {
      token:this.state.token
    }


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetMemberCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMemberApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  getService = () => {
   
    const header = {
      token:this.state.token
    }


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetServiceCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getServiceApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  // Customizable Area End
}
