import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
interface Referralsint{
  reserve_beds_count:number,
  my_org_workers_id:number,
  worker_name:string,
  type:string
}
export interface Item {
  id: string;
  type: string;
  attributes: {
    id: string;
    price: number;
    state: string;
    full_address: string;
    city: string;
    reservation_date: string;
    zip_code: number,
    created_at: string;
    updated_at: string;
    booking_status: string;
    slot_start_time: string;
    slot_end_time: string;
    image: string;
    service_name: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  propId: string;
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End

interface SProps {
  // Customizable Area Start
  itemsList: Item[];
  searchedItem: Item[];
  searchText: string;
  startDate: string;
  endDate: string;
  isSortOpen: boolean;
  order: string;
  password: string;
  token: string;
  referrals:Referralsint[]
  // Customizable Area End
}

interface SSProps {
  // Customizable Area Start
  ssId: string;
  // Customizable Area End
}

export default class ReservationsController extends BlockComponent<
  Props,
  SProps,
  SSProps
> {
  // Customizable Area Start
  uniqueSessionRequesterId: string = "";
  authToken: string = "";
  getProductItemListApiCallId: string = "";
  createAccountApiCallId: string = "";
  apiGetreferralCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      itemsList: [],
      searchedItem: [],
      searchText: '',
      startDate: '',
      endDate: "",
      isSortOpen: false,
      order: "descending",
      password: "",
      token:"",
      referrals:[]
      // Customizable Area End
    };
    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );

      if (requesterId === this.uniqueSessionRequesterId) {
        const sessionToken = message.getData(
          getName(MessageEnum.SessionResponseToken)
        );
        this.authToken = sessionToken;
        this.fetchServiceList();
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getProductItemListApiCallId) {
        this.setState({ itemsList: responseJson.data, searchedItem: responseJson.data })
      }
      if (
        apiRequestCallId &&
        responseJson &&
        apiRequestCallId === this.createAccountApiCallId
      ) {
        await this.saveLoggedInUserData(responseJson);
        this.fetchServiceList();
      }
      if (apiRequestCallId === this.apiGetreferralCallId) {
        this.setState({ referrals: responseJson.data })
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  
  createAccount() {
    const header = {
      "Content-Type": configJSON.itemListApiContentType
    };

    const httpBody = {
      "data": {
        "attributes":
        {
          "email": "testpartner15@yopmail.com",
          "password": this.state.password
        },
        "type": "email_account"
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.secureReservationApiMethodType
    );
    
  }
  async saveLoggedInUserData(responseJson: { meta: { token: string } }) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const sessionMsg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      sessionMsg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      sessionMsg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(sessionMsg);
    }
  }

  btnHistoryExample = () => {
    this.props.navigation.navigate("ReservationHistory");
  };

  fetchServiceList = () => {
    const itemHeaders = {
      "Content-Type": configJSON.itemListApiContentType,
      "token": this.authToken
    };

    const getServiceListMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductItemListApiCallId = getServiceListMsg.messageId;

    getServiceListMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetItemList
    );

    getServiceListMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(itemHeaders)
    );
    getServiceListMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.itemListApiMethodType
    );

    runEngine.sendMessage(getServiceListMsg.id, getServiceListMsg);
    return true;
  }

  toggleFilter = () => {
    this.setState({ isSortOpen: !this.state.isSortOpen })
  }
  toggleSort = (item: string) => {
    this.setState({ order: item }, () => {
      this.sortById(this.state.itemsList, this.state.order)
    })
  }
  sortById = (arrayData: Item[], order: string) => {
    arrayData.sort((item1, item2) => {
      if (order === "descending") {
        return Number(item1.id) < Number(item2.id) ? 1 : -1
      } else {
        return Number(item1.id) < Number(item2.id) ? -1 : 1
      }
    })
    this.setState({ searchedItem: arrayData })
  }

  handleSearch = (text: string) => {
    let arrayData: Item[] = this.state.itemsList;
    this.setState({ searchText: text })
    if (text.length > 0) {
      const newData = arrayData.filter(function (item: Item) {
        return (item.attributes.service_name.toLowerCase().includes(text.toLowerCase()) ||
          moment(item.attributes.reservation_date).format("DD-MMM-YYYY").toLowerCase().includes(text.toLowerCase())
        );
      });
      this.setState({ searchedItem: newData, searchText: text }, () => {
        this.sortById(this.state.searchedItem, this.state.order)
      })
    } else {
      this.setState({ searchedItem: arrayData, searchText: text }, () => {
        this.sortById(this.state.searchedItem, this.state.order)
      })
    }
  }
  handleViewDetails = (item: Item) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'ReservationsItem'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      data: item,
      type: "pre-booked"
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  handleStartDate = (date: string) => {
    this.setState({ startDate: date })
  }
  handleEndDate = (date: string) => {
    this.setState({ endDate: date })
  }
  handleSortByDate = () => {
    let arrayData: Item[] = this.state.itemsList;
    const start = new Date(this.state.startDate);
    const endd = new Date(this.state.endDate);
    const newData = arrayData.filter((item: Item) => {
      return moment(item.attributes.reservation_date).isBetween(start, endd);
    })
    this.setState({ searchedItem: newData }, () => {
      this.sortById(this.state.searchedItem, this.state.order)
    });
  }

  handleGoBack = () =>{
    window.history.back();
  }
  searchFilterFunction = (text: string) => {
    this.setState({searchText:text},()=>this.getallReferrals())
  }
  clear=()=>{
    this.setState({searchText:""},()=>this.getallReferrals())
   }
   gotoPage(page: string,rowData:number){
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), page);
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
   );
   raiseMessage.addData("rowData", rowData);
   navigateMsg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(navigateMsg);
  }
  getToken = async () => {
    const token = await getStorageData("token")
    this.setState({token:token},()=>this.getallReferrals())
  };
  getallReferrals= () => {
    let endpointUrl = configJSON.getReferralEndpoint

    let queryParams: string[] = [];
    if (this.state.searchText.length>0) {
     queryParams.push(`${this.state.searchText.toLowerCase()}`);
    }

    if (queryParams.length > 0) {
     endpointUrl += `?query=` + queryParams.join('&');
   }
    
    const header = {
      token:this.state.token
    }
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apiGetreferralCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpointUrl
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.itemListApiMethodType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
   
  };
  // Customizable Area End
}

