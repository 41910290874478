export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const profile = require("../assets/profile.png");
export const back = require("../assets/back.png");
export const brush = require("../assets/brush.png");
export const search = require("../assets/search.png");
export const more = require("../assets/more.png");
export const editOrg = require("../assets/editOrg.jpeg");
