import React from "react";
// Customizable Area Start
import {
    Grid,
    Typography, Box, Button, FormControl, Radio, FormControlLabel, RadioGroup, InputLabel, Select, Divider,
    TextField,
  } from "@mui/material";

  import { styled } from '@mui/material/styles';
  import DatePicker from "react-datepicker";
  import "react-datepicker/dist/react-datepicker.css";
  import { View, Dimensions, Platform } from "react-native";
  import { filterIcon, searchIcon, leftIcon } from "./assets";
  import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu";

  const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '25%',
    width: 24,
    height: 24,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    border: "1px solid #64748B",
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
      ...theme.applyStyles('dark', {
        backgroundColor: '#30404d',
      }),
    },
    ...theme.applyStyles('dark', {
      boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
      backgroundColor: '#394b59',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
    }),
  }));

  const BpCheckedIcon = styled(BpIcon)({
    border: "1px solid #CF9500",
    boxShadow: "none",
    backgroundColor: '#CF9500',
    backgroundImage: "none",
    borderRadius: '6px',
    width: '24px',
    height: '24px',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '45%',
      left: '45%',
      width: '6px',
      height: '12px',
      border: 'solid #fff',
      borderWidth: '0 3px 3px 0',
      transform: 'translate(-50%, -50%) rotate(45deg)',
      borderRadius: '2px',
    },
    'input:hover ~ &': {
      backgroundColor: '#CF9500',
    },
  });




  const DatePickerComp = styled(Box)(({ theme }) => ({
    ".react-datepicker": {
    width: "100%", 
    maxWidth: "100%",
  },
  ".react-datepicker__month-container": {
    width: "100%", 
    maxWidth: "100%",
  },
    '.react-datepicker-wrapper': {
      width: "100%",
    },
    ".react-datepicker__input-container": {
      width: "100%",
    },
    ".react-datepicker__day-names": {
      display: "grid",
      gridTemplateColumns: "repeat(7, 1fr)", 
      width: "100%",
      textAlign: "center",
      margin:"0.4rem",
    },
    ".react-datepicker__week": {
      display: "grid", 
      gridTemplateColumns: "repeat(7, 1fr)",
      width: "100%",
      textAlign: "center",
      margin:"0",
      padding:"0",
    },
    ".react-datepicker__input-container input": {
      width: "100%",
      padding: "15px",
      borderRadius: "5px",
      outline: "none",
      borderColor: "#CBD5E1",
      borderWidth: "1px",
      borderStyle: "solid",
    },
    ".react-datepicker__header": {
      textAlign: "center",
      backgroundColor: "#fff",
      borderBottom: "none",
      padding: "17px 0 0 0",
      position: "relative",
    },
    ".react-datepicker__day": {
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      height: "2.5em",
      width: "100%",
      maxWidth:"100%",
      margin: "0", 
    },
    ".react-datepicker__day--in-range": {
      backgroundColor: "#FFF4DB",
      color: "#000000",
      borderRadius: "0", 
      border: "none",
    },
    ".react-datepicker__day--range-start": {
      backgroundColor: "#FFF4DB", 
      color: "#000000",
      borderRadius: "25% 0 0 25%",
    },
    ".react-datepicker__day--range-end": {
      backgroundColor: "#FFF4DB", 
      color: "#000000",
      borderRadius: "0 25% 25% 0",
    },
    ".react-datepicker__day--selected": {
      backgroundColor: "#FFF4DB",
      color: "#000000",
      borderRadius: "50%", 
      border: "none",
    },
  }));



  // Inspired by blueprintjs
  function BpRadio(props: any) {
    return (
      <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }
// Customizable Area End

const viewportHeight = Dimensions.get("window").height;
const viewportWidth = Dimensions.get("window").width;

import MapView from "react-native-maps";

import MapsController, { Props, configJSON } from "./MapsController";

export default class Maps extends MapsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isToggled, selectedFilter, selectedService} = this.state;

      return (
        <div>
          <NavigationMenu navigation={this.props.navigation} id={""} >
            <Grid container sx={{ flexDirection: { md: "row-reverse" }, backgroundColor: "#f3f3f1" }}>
              <Grid item xs={12} md={8}>
                <View style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}>
                  <Grid container direction="column">
                    <Grid item xs={8} style={{ padding: 5 }}>
                      <View style={{
                        display: "flex",
                        paddingTop: "20px", padding: 10
                      }}>
                        <Typography component="h1" style={{ fontSize: "14px" }} >
                          <span style={{ color: "#1D4ED8" }} onClick={() => this.props.navigation.goBack()}> <img src={leftIcon.default} alt="arow icon" width={"16px"} height={"16px"} style={{ transform: "translateY(3px)" }} /> Go Back</span>
                        </Typography>
                        <Typography sx={{
                          flex: 1,
                          padding: "10px", fontWeight: "700", fontSize: "24px", font: "Inter"
                        }}>
                          Maps
                        </Typography>
                        <Typography sx={{
                          flex: 1,
                          padding: "10px", fontWeight: "700", fontSize: "17px", font: "Inter"
                        }}>
                          Outreach Workers Performance
                        </Typography>
                      </View>
                    </Grid>
                    <Grid item xs={8} style={{ padding: 5 }}>
                      <View style={{
                        display: "flex",
                        paddingTop: "20px", padding: 10
                      }}>
                        <Box sx={{ background: "#fff", borderRadius: "10px", padding: "10px 20px" }}>
                          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Typography sx={{

                              flex: 1,
                              fontWeight: "700", fontSize: "16px", font: "Inter"
                            }}>
                              Filter:
                            </Typography>
                            <div>
                              <Button onClick={this.handleToggle} data-test-id="toggle_button" sx={{
                                border: "1px solid #CBD5E1",
                                height: "45px",
                                minWidth: '45px',
                                borderRadius: "8px",
                                boxShadow: '0px 2px 8px 0px #00000014',
                              }}>
                                <img src={filterIcon.default} alt="Filter Icon" />
                              </Button>
                            </div>
                          </Box>
                          {isToggled &&
                            <div>
                              <div style={styles.filterContainer}>
                                <FormControl component="fieldset" style={{ width: "100%", display: "block" }}>
                                  <RadioGroup
                                    test-id = "radioButton"
                                    value={selectedFilter}
                                    onChange={this.handleFilterChange}
                                  >
                                    <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
                                      {["All", "Team Member", "Service Type", "Date Range"].map((label) => (
                                        <li style={{ borderBottom: "1px solid #ddd", padding: "5px", marginLeft: "10px" }}> <FormControlLabel
                                          key={label}
                                          value={label}
                                          control={<BpRadio />}
                                          label={label}
                                        /></li>
                                      ))}
                                    </ul>
                                  </RadioGroup>
                                </FormControl>
                              </div>
                              {selectedFilter === 'Service Type' && (
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                <InputLabel>Select a Service</InputLabel>
                                <Select
                                  value={selectedService}
                                >
                                </Select>
                              </FormControl>
                          
                              )}
                         {selectedFilter === "Date Range" && (
                              <>
                                <Box sx={styles.dateRangeContainer}>
                                  <Box sx={styles.datePickerRow}>
                                    <Typography sx={styles.dateLabel}>From:</Typography>
                                    <DatePickerComp>
                                      <TextField
                                       data-testid="start-date-input"
                                       variant="outlined"
                                       value={this.state.startDate ? this.state.startDate.toLocaleDateString() : ""}
                                        onClick={() => this.handleActivePicker("start")}
                                        sx={styles.inputRoot}
                                      />
                                    </DatePickerComp>
                                  </Box>

                                  <Box sx={styles.datePickerRow}>
                                  <Typography sx={styles.dateLabel}>To:</Typography>
                                  <DatePickerComp>
                                    <TextField
                                    data-testid="end-date-input"
                                     variant="outlined"
                                     value={this.state.endDate ? this.state.endDate.toLocaleDateString() : ""}
                                     onClick={() => this.handleActivePicker("end")}
                                     sx={styles.inputRoot}
                                    />
                                  </DatePickerComp>
                                  </Box>
                              

                                <DatePickerComp>
                                  {this.state.activePicker === "start" && (
                                     <DatePickerComp>
                                    <DatePicker
                                      selected={this.state.startDate}
                                      onChange={this.handleStartDateChange}
                                      inline
                                      maxDate={this.state.endDate}
                                    />
                                    </DatePickerComp>
                                  )}

                                  {this.state.activePicker === "end" && (
                                    <DatePicker
                                      selected={this.state.endDate}
                                      onChange={this.handleEndDateChange}
                                      inline
                                      minDate={this.state.startDate}
                                    />
                                  )}

                                  {this.state.activePicker === "dateRange" && (
                                    <DatePicker
                                      startDate={this.state.startDate}
                                      endDate={this.state.endDate}
                                      inline
                                    />
                                  )}
                                </DatePickerComp>
                                </Box>
                              </>
                          )}

                            </div>
                          }
                        </Box>
                        <Divider sx={{ my: 2 }} />
                        <Box sx={{ position: "relative" }}>
                          <img style={{ position: "absolute", top: "10px", left: "6px" }} src={searchIcon.default} alt="search icon" />
                          <input type="text" placeholder="Search on map" style={{ width: "100%", borderRadius: "8px", outline: "none", border: "1px solid #ddd", padding: "14px 0 12px 38px", fontSize: "14px" }} />
                        </Box>
                      </View>
                    </Grid>
                    <Grid item xs={8} style={{ padding: 5 }}>
                      <View>
                        <MapView
                          style={styles.map}
                          initialRegion={{
                            latitude: 37.78825,
                            longitude: -122.4324,
                            latitudeDelta: 0.0922,
                            longitudeDelta: 0.0421,
                          }}
                        />
                      </View>
                    </Grid>
                  </Grid>
                </View>
              </Grid>
            </Grid>
          </NavigationMenu>
        </div>
      );
    }
  // Customizable Area End
}

// Customizable Area Start
  const styles = {
    container: {
      flex: 1,
      padding: 16,
      marginLeft: "auto",
      marginRight: "auto",
      width: Platform.OS === "web" ? "75%" : "100%",
      maxWidth: 650,
      backgroundColor: "#ffffffff",
    },
    map: {
      width: "100%",
      height: "400px",
      // position: "absolute",
      // top: 0,
      // bottom: 0,
    },
    main: {
      display: "flex",
      alignItems: "center"
    },
    body: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "10px",
      width: "50%",
    },
    textFont: {
      fontFamily: "Helvetica",
    },
    formWrapper: {
      display: "flex",
      width: "100%",
      paddingTop: "20px"
    },
    inputWrapper: {
      flex: 1,
      padding: "20px"
    },
    removeBackground: {
      backgroundColor: "transparent"
    },
    inputDefaultLabel: {
      color: "#94A3B8",
      fontSize: 14,
      fontWeight: "400"
    },
    formControl: {
      backgroundColor: '#ffffff',
      height: "44px",
      marginTop: "20px"
    },
    menuItems: {
      backgroundColor: '#ffffff',
      color: "#2D2A24",
      fontSize: 14,
      fontWeight: "700",
      '&:hover': {
        background: '#FFFDE7',
      },
    },
    menuItemsSecond: {
      fontWeight: "700",
      backgroundColor: '#ffffff',
      fontSize: 14,
      color: "#2D2A24",
      '&:hover': {
        background: '#FFFDE7',
      },
    },
    checkBoxText: {
      fontSize: 18,
      color: "#6200EE",
      fontWeight: "bold",
    },
    buttonsWrapper: {
      flex: 1,
      padding: "10px",
    },
    OrWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "10px",
    },
    footerText: {
      textAlign: "center",
      margin: 5
    },
    headerLogo: {
      width: "122.32px",
      height: "28px",
      backgroundColor: "#F5C502",
      borderRadius: 13,
      marginRight: 10
    },
    filterContainer: {
      border: "1px solid #CBD5E1",
      borderRadius: 8,
      marginTop: "5px"
    },

    dateRangeContainer: {
      marginTop: "20px",
      display: "flex",
      gap: "5px",
      flexDirection: "column"
    },
    datePickerRow: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center"
    },
    dateLabel: {
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: 400
    },
    inputRoot: {
      '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#CBD5E1', 
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#FFF787', 
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#FFF787', 
        },
      },
    },

  };
// Customizable Area End
