import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    RadioProps,
    Select,
    TextField,
    Tooltip,
    Typography,
    styled
} from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EditIcon from "@mui/icons-material/Edit";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { filterIcon } from "../../../blocks/maps/src/assets";
import DatePicker from 'react-datepicker';

function BpRadio(props: RadioProps) {
    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}
// Customizable Area End

import SubmittedFormsController, { Props, servicesValueArr } from "./SubmittedFormsController.web";

export default class SubmittedForms extends SubmittedFormsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    

     
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { isToggled, selectedFilter, data, selectedService, selectedDate, isServicesOpen } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <NavigationMenu navigation={this.props.navigation} id={""}>
                <Container maxWidth={"md"}>
                    <Box sx={styles.container}>
                        <Box onClick={this.handleGoBack} sx={styles.goback}>
                            <ArrowBackIosIcon sx={styles.arrowIcon} />
                            <Typography sx={styles.goBackText}>Go Back</Typography>
                        </Box>
                        <Typography variant="h6" sx={styles.heading}>Update Submitted Forms</Typography>
                        <Box sx={styles.mainBox}>
                            <Box sx={styles.filterBox}>
                                <Typography sx={styles.filterText}>Filter:</Typography>
                                <div>
                                    <Button onClick={this.handleToggle} data-test-id="toggle_button" sx={styles.filterButton}>
                                        <img src={filterIcon.default} alt="Filter" />
                                    </Button>
                                </div>
                            </Box>
                            {isToggled &&
                                <>
                                    <div style={styles.filterContainer}>
                                        <FormControl component="fieldset" style={{ width: "100%", display: "block" }}>
                                            <RadioGroup
                                                test-id="radioButton"
                                                value={selectedFilter}
                                                onChange={this.handleFilterChange}>
                                                <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
                                                    {["All", "Date", "Type"].map((label, index) => (
                                                        <li style={{ borderBottom: index == 2 ? "none" : "1px solid #ddd", padding: "5px" }}>
                                                            <FormControlLabel
                                                                key={label}
                                                                value={label}
                                                                label={label}
                                                                control={<BpRadio />}
                                                                style={{ marginLeft: "1px" }}
                                                            />
                                                        </li>
                                                    ))}
                                                </ul>
                                            </RadioGroup>
                                        </FormControl>
                                    </div>

                                    {selectedFilter == "Date" && (
                                        <Box sx={{ marginTop: "10px", width: "100%", gap: "10px" }}>
                                            <TextField
                                                data-testid="selected-date-input"
                                                variant="outlined"
                                                value={selectedDate && selectedDate.toLocaleDateString()}
                                                sx={styles.inputRoot}
                                            />
                                            <DatePickerComp >
                                                <DatePicker
                                                    selected={selectedDate}
                                                    onChange={this.handleDateChange}
                                                    dateFormat="yyyy-MM-dd"
                                                    inline
                                                />
                                            </DatePickerComp>
                                        </Box>
                                    )}

                                    {selectedFilter == "Type" &&
                                        <Box sx={{ marginTop: "10px" }}>
                                            <FormControl fullWidth>
                                                <Select
                                                    labelId="dropdown-label"
                                                    data-test-id="selected-service-input"
                                                    value={selectedService}
                                                    onClick={this.handleServiceToggle}
                                                    open={false}
                                                    sx={styles.serviceSelect}
                                                >
                                                    <MenuItem value="" disabled>
                                                        Select a Form
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                            {isServicesOpen && (
                                                <Paper sx={{ marginTop: "10px", borderRadius: "8px", border: "1px solid #E2E8F0", boxShadow: 2 }}>
                                                    {servicesValueArr.map((service) => (
                                                        <MenuItem
                                                            key={service.value}
                                                            data-test-id="menuItem"
                                                            onClick={() => this.handleServiceChange(service.value)}
                                                            sx={styles.menuItem}>
                                                            <Typography sx={styles.label}>{service.label}</Typography>
                                                        </MenuItem>
                                                    ))}
                                                </Paper>
                                            )}
                                        </Box>
                                    }
                                </>
                            }
                        </Box>
                        <Divider sx={{ my: 2, borderBottom: "2px" }} />
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    <Box>
                        <Box sx={{ backgroundColor: "#f5f5f5", padding: "10px" }}>
                            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "16px", padding: "5px" }}>
                                <Box sx={{ flex: 1 }}>
                                    <Typography variant="h6" sx={styles.tableHeading}>
                                        Date
                                    </Typography>
                                </Box>
                                <Box sx={{ flex: 2.5 }}>
                                    <Typography variant="h6" sx={styles.tableHeading}>
                                        Type
                                    </Typography>
                                </Box>
                                <Box sx={{ flex: 1 }} />
                            </Box>
                            {data.map((formData, index) => (
                                <Box
                                    key={index}
                                    sx={styles.dataBox}
                                >
                                    <Typography sx={{ flex: 1, color: "#2D2A24", fontSize: "12px" }}>
                                        {formData.date.toLocaleDateString()}
                                    </Typography>
                                    <Tooltip title={formData.type} arrow>
                                        <Typography
                                            sx={{
                                                flex: 2.5,
                                                color: "#2D2A24",
                                                fontSize: "12px",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            {this.getServiceLabel(formData.type)}
                                        </Typography>
                                    </Tooltip>
                                    <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
                                        <IconButton sx={styles.IconStyles} data-test-id="edit_button" onClick={()=>this.gotoPage(this.getServicePath(formData.type, formData.id))}>
                                            <EditIcon sx={{ color: "#2D2A24" }} />
                                        </IconButton>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Container>
            </NavigationMenu>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 24,
    height: 24,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
        ...theme.applyStyles('dark', {
            backgroundColor: '#30404d',
        }),
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
        ...theme.applyStyles('dark', {
            background: 'rgba(57,75,89,.5)',
        }),
    },
    ...theme.applyStyles('dark', {
        boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
        backgroundColor: '#394b59',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
    }),
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#CF9500',
    backgroundImage: 'linear-gradient(180deg, hsla(45, 100%, 50%, 0.1), hsla(45, 100%, 60%, 0))',
    '&::before': {
        display: 'block',
        width: 24,
        height: 24,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#CF9500',
    },
});

const DatePickerComp = styled(Box)(({ theme }) => ({
    ".react-datepicker": {
        width: "100%",
        maxWidth: "100%",
        border: "1px solid #E2E8F0",
        borderRadius: "8px",
    },
    ".react-datepicker__month-container": {
        width: "100%",
        maxWidth: "100%",
    },
    '.react-datepicker-wrapper': {
        width: "100%",
    },
    ".react-datepicker__input-container": {
        width: "100%",
    },
    ".react-datepicker__day-names": {
        display: "grid",
        gridTemplateColumns: "repeat(7, 1fr)",
        width: "100%",
        textAlign: "center",
        margin: "0.4rem",
    },
    ".react-datepicker__week": {
        display: "grid",
        gridTemplateColumns: "repeat(7, 1fr)",
        width: "100%",
        textAlign: "center",
        margin: "0",
        padding: "0",
    },
    ".react-datepicker__input-container input": {
        width: "100%",
        padding: "15px",
        borderRadius: "5px",
        outline: "none",
        borderColor: "#CBD5E1",
        borderWidth: "1px",
        borderStyle: "solid",
    },
    ".react-datepicker__header": {
        textAlign: "center",
        backgroundColor: "#fff",
        borderBottom: "none",
        padding: "17px 0 0 0",
        position: "relative",
    },
    ".react-datepicker__day": {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        height: "2.5em",
        width: "100%",
        maxWidth: "100%",
        margin: "0",
    },
    ".react-datepicker__day--selected": {
        backgroundColor: "#FFFDE7",
        border: "1px solid #FFEC42",
        borderRadius: "20%",
        color: "#333",
    },
}))

const styles = {
    container: {
        flex: 1,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "20px",
        alignItems: "center",
        backgroundColor: "#fff",
    },
    goback: {
        height: "22px",
        width: "80px",
        display: "flex",
        gap: "5px",
        alignItems: "center",
        marginBottom: "10px",
    },
    arrowIcon: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#2D2A24"
    },
    goBackText: {
        color: "#1D4ED8",
        fontSize: "14px",
        fontWeight: 400,
    },
    heading: {
        fontSize: "24px",
        lineHeight: "32px",
        fontWeight: 700,
        fontFamily: "Inter",
        marginBottom: "15px"
    },
    mainBox: {
        background: "#FFFFFF",
        borderRadius: "10px",
        padding: "10px 20px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)"
    },
    filterBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    filterText: {
        flex: 1,
        fontWeight: "700",
        fontSize: "16px",
        font: "Inter"
    },
    filterButton: {
        border: "1px solid #CBD5E1",
        height: "45px",
        minWidth: '45px',
        borderRadius: "8px",
        boxShadow: '0px 2px 8px 0px #00000014',
    },
    filterContainer: {
        border: "1px solid #CBD5E1",
        borderRadius: 8,
        marginTop: "5px"
    },
    services: {
        borderRadius: "8px",
        backgroundColor: "#fff",
    },
    serviceSelect: {
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        "&.Mui-focused": {
            border: "1px solid #CBD5E1",
        },
        "&:hover": {
            border: "1px solid #CBD5E1",
        }
    },
    menuItem: {
        padding: "10px",
        borderBottom: "1px solid #E2E8F0",
        "&.Mui-selected": {
            backgroundColor: "#fffde7 !important",
        },
        "&:last-child": { borderBottom: "none" },
        "&:focus": {
            backgroundColor: "#fffde7",
            outline: "none",
        },
        "&:active": {
            backgroundColor: "#fffde7",
        },
    },
    label: {
        color: "#2D2A24",
        fontSize: "12px",
        fontWeight: 700,
        fontFamily: "Inter",
    },
    select: {
        backgroundColor: "#fff",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        padding: "8px",
        "& .MuiSelect-icon": {
            fontSize: "20px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
        "&:hover": {
            backgroundColor: "#f9f9f9",
        },
    },
    selectedItem: {
        backgroundColor: "#fffde7 !important",
    },
    inputRoot: {
        width: "100%",
        borderRadius: "8px",
        marginBottom: "10px",
        '& .MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#CBD5E1',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#FFF787',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#FFF787',
            },
        },
    },
    dataBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#fff",
        borderRadius: "5px",
        padding: "5px",
        marginBottom: "12px",
        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
    },
    tableHeading: {
        fontWeight: 700,
        fontSize: "12px",
        fontFamily: "Inter"
    },
    IconStyles: {
        backgroundColor: "#FCE38B",
        borderRadius: "8px",
        padding: "8px",
        "&:hover": {
            backgroundColor: "#FCE38B",
        },
        '&:focus': {
            backgroundColor: '#FCE38B', 
        },
    }

}
// Customizable Area End