import React from "react";

// Customizable Area Start
import {
  View
} from "react-native";
import {
  Grid,
  Button,
  Typography,
  Box
} from "@mui/material";
import { arrow, button, datat, maps, referrals, refs, settings } from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
// Customizable Area End

import LandingPageController, {
    Props,
    configJSON
  } from "./LandingPageController";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <NavigationMenu navigation={this.props.navigation} id={""}>
      <Box style={webStyle.landingPageView}>
           <View style={{backgroundColor:"#f3f3f1"}} >
       
  <Grid item xs={12} sx={{paddingBottom: 3}} >
       
          <Grid container direction="column"  >
        
        
        {this.state.role === "Outreach Worker" ?
        
       
        <Grid  direction="column" style={webStyle.formWrapper}>
        
          <View style={webStyle.inputWrapper}>
          <Typography component="h6" sx={{fontSize:"24px",fontWeight:"700"}} >
          Outreach Worker Console
        </Typography>
        <Typography sx={{fontSize:"18px", fontWeight:"700",marginBottom:0.5,wordWrap: "break-word"}} >
        {this.state.orgnizationData} / {this.state.firstName} {this.state.lastName}
        </Typography>
  <Typography component="h1" sx={{fontSize:"14px", fontWeight:"400"}} style={webStyle.textFont}>
  You will be able to access the services that can be provided to the community.
        </Typography>
     
          
          </View>
       
          <View
            style={webStyle.buttonsWrapper}
          >
            <Button onClick={()=>this.goToPage("CustomForm")} sx={[webStyle.buttonsWrapper,webStyle.workerButton]} data-test-id={"btnSafety"}
            >Completed Clean-up or Request Clean-up</Button>
          </View>
          <View
            style={webStyle.buttonsWrapper}
          >
            <Button onClick={()=>this.goToPage("HarmReductionForm")} sx={[webStyle.buttonsWrapper,webStyle.workerButton]} data-test-id={"btnHarm"}
            >Harm Reduction Supplies Distribution</Button>
          </View>
          <View
            style={webStyle.buttonsWrapper}
          >
            <Button onClick={()=>this.goToPage("HealthcareAccessForm")} sx={[webStyle.buttonsWrapper,webStyle.workerButton]} data-test-id={"btnHealthcare"}
            >Referrals and Warm Hand Offs</Button>
          </View>
          <View
            style={webStyle.buttonsWrapper}
          >
            <Button onClick={()=>this.goToPage("AdministratedServices")} sx={[webStyle.buttonsWrapper,webStyle.workerButton]} data-test-id={"btnAdministered"}
            >Performed and Facilitated Care</Button>
          </View>
          <View
            style={webStyle.buttonsWrapper}
          >
            <Button onClick={()=>this.goToPage("ReferralsUpdate")}  sx={[webStyle.buttonsWrapper,webStyle.workerButton]} data-test-id={"btnBookingsRef"}
            >Update Referrals</Button>
          </View>
          <View
            style={webStyle.buttonsWrapper}
          >
            <Button onClick={()=>this.goToPage("UpdateSubmittedForms")} sx={[webStyle.buttonsWrapper,webStyle.workerButton]} data-test-id={"btnBookings"}
            >Update Submitted Forms</Button>
          </View>
          
        
        </Grid>
        :
        
        <Grid item xs={8} >

<Grid style={webStyle.formWrapper}>
<View style={webStyle.inputWrapper}>
          <Typography style={webStyle.header}>Organization Console</Typography>
          <Typography style={webStyle.header2}>{this.state.orgnizationData} </Typography>
         <Typography style={webStyle.header3}>Add/Edit your outreach workers and take a look into data extracts.</Typography>
         <Grid container style={{marginTop:10,padding:10}}>
          <Grid item xs={2} >
            <img src={referrals.default} style={{height:"56px"}}  />
          </Grid>
          <Grid item xs={8}>
            <Grid container style={{paddingLeft:15}} justifyContent={"center"} alignItems={"center"}>
              <Grid item xs={12}><Typography style={webStyle.textBold}>Maintenance</Typography></Grid>
              <Grid item xs={12}><Typography style={webStyle.textSmall}>You'll be able to add/edit the organization and users.</Typography></Grid>
            </Grid>
          </Grid>
          <Grid onClick={()=>this.goToPage("Rolesandpermissions2")}  item xs={2}>
            <div style={{height:3,width:3}}>   <img style={{height:"56px"}} src={settings.default} alt="mainImg" /></div>
       
          </Grid>
         </Grid>
         <Grid container style={{marginTop:10,padding:10}}>
          <Grid item xs={2} >
          <img src={refs.default} style={{height:"56px"}}  />
          </Grid>
          <Grid item xs={8}>
          <Grid container style={{paddingLeft:15}} justifyContent={"center"} alignItems={"center"}>
              <Grid item xs={12}><Typography style={webStyle.textBold}>Referrals</Typography></Grid>
              <Grid item xs={12}><Typography style={webStyle.textSmall}>You'll be able to manage the organization's bookings.</Typography></Grid>
            </Grid>
          </Grid>
          <Grid onClick={()=>this.goToPage("Referrals")} item xs={2}>
          <div style={{height:3,width:3}}>   <img style={{height:"56px"}} src={button.default} alt="mainImg" /></div>
       
          </Grid>
         </Grid>
         <Grid container style={{marginTop:10,padding:10}}>
          <Grid item xs={2} >
          <img src={maps.default} style={{height:"56px"}}  />
          </Grid>
          <Grid item xs={8}>
          <Grid container style={{paddingLeft:15}} justifyContent={"center"} alignItems={"center"}>
              <Grid item xs={12}><Typography style={webStyle.textBold}>Maps</Typography></Grid>
              <Grid item xs={12}><Typography style={webStyle.textSmall}>You'll be able to look at the map and see Outreach performance.</Typography></Grid>
            </Grid>
          </Grid>
          <Grid onClick={()=>this.goToPage("Maps")} item xs={2}>
          <div style={{height:3,width:3}}>   <img style={{height:"56px"}} src={arrow.default} alt="mainImg" /></div>
       
          </Grid>
         </Grid>
         <Grid container style={{marginTop:10,padding:10}}>
          <Grid item xs={2} >
          <img src={datat.default} style={{height:"56px"}}  />
          </Grid>
          <Grid item xs={8}>
          <Grid container style={{paddingLeft:15}} justifyContent={"center"} alignItems={"center"}>
              <Grid item xs={12}><Typography style={webStyle.textBold}>Data Extracts</Typography></Grid>
              <Grid item xs={12}><Typography style={webStyle.textSmall}>You'll be able to see and analyze work performance.</Typography></Grid>
            </Grid>
          </Grid>
          <Grid onClick={()=>this.goToPage("ImportExportData")} item xs={2}>
          <div style={{height:3,width:3}}>   <img style={{height:"56px"}} src={arrow.default} alt="mainImg" /></div>
          </Grid>
         </Grid>
         </View>
        </Grid> 
        </Grid>}
      
          </Grid>
       
        </Grid>
 
        </View>
    </Box>
    </NavigationMenu>
      // Customizable Area End
    );
  }

}

// Customizable Area Start
const webStyle = {
  landingPageView: {
    // paddingBottom: 20
  },
  landingPageText: {
    fontSize: 42,
    letterSpacing: 2,
    fontWeight: "bold",
    color: "#323441",
    marginTop: 15
  },
  clearBtnStyle: {
    marginRight: 15,
  },
  footer: {
    backgroundColor: '#333',
    color: 'white',
    padding: '20px',
    textAlign: 'center' ,
    justifyContent:"center",
    alignItems:"center",
    textAlignVertical:"center",
    alignContent:"center",
    // marginTop:3
    
  },
  footerContent: {
    margin: '0 auto',
  },
  textFont: {
    fontFamily: "Helvetica",
    // padding:5,
  },
  formWrapper: {
    display: "flex",
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  buttonsWrapper: {
    flex: 1,
    textTransform: 'none',
  },
  btnStyle:{
    margin:"30px",marginLeft:15,marginRight:15,background: "#F5C502",color:"black"  ,'&:hover': {backgroundColor: '#FFC300',  
            color: '#black'}
  },
  textBold:{
    fontWeight: "700",
    font:"Inter",
    fontSize:"14px"
  },
  textSmall:{
    font:"Inter",
    fontWeight: "400",
    fontSize:"12px"
  },
  footerText:{
    textAlign:"center",
    fontWeight:"700",
    fontSize:"14px",
    margin:1
  },
  header:{font:"Inter",fontSize:"24px",lineHeight:"32px",fontWeight:"700"},
  header2:{font:"Inter",fontSize:"18px",lineHeight:"26px",fontWeight:"700",margin:2},
  header3:{font:"Inter",fontSize:"14px",lineHeight:"22px",fontWeight:"400",margin:3},
  workerButton:{margin:"10px",marginLeft:3,marginRight:3,fontSize:"16px",fontWeight:"700",background: "#F5C502",color:"black"  ,
    '&:hover': {backgroundColor: '#FFC300',  
    color: '#black',  }}
}
  // Customizable Area End
