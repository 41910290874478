Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Maps";
exports.labelBodyText = "Maps Body";

exports.btnExampleTitle = "CLICK ME";
exports.getLocationsApiENdPoint="bx_block_maps3/admin_maps"
exports.getMemberApiEndPoint="bx_block_maps3/admin_maps/team_member"
exports.getServiceApiEndPoint="bx_block_maps3/admin_maps/service_type"
// Customizable Area End